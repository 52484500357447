import Vue from 'vue'
import { MutationTree } from 'vuex'
import StateTheme from './types'
import { MUTATIONS } from './consts'
import type { Theme } from '@simpl/core/types/graphql'
import type { EditableTheme, EditableThemeAppModule } from '../types'
import { createEditableTheme, themeToEditableTheme } from '../utils'

const mutations = {
  [MUTATIONS.SET_THEMES]: (state, payload: Theme[]) => {
    state.remoteThemes = payload.slice()

    state.themes = payload.length > 1
      ? payload.map(themeToEditableTheme)
      : payload.length > 0
        ? [themeToEditableTheme(payload[0]), createEditableTheme(!payload[0]!.dark)]
        : [createEditableTheme(true), createEditableTheme(false)]

    state.originalThemes = payload.length > 1
      ? payload.map(themeToEditableTheme)
      : payload.length > 0
        ? [themeToEditableTheme(payload[0]), createEditableTheme(!payload[0]!.dark)]
        : [createEditableTheme(true), createEditableTheme(false)]

    state.theme = state.themes.find(t => t.preferred) || state.themes[0]
  },
  [MUTATIONS.SET_THEME]: (state, payload: EditableTheme) => {
    state.theme = payload
  },

  [MUTATIONS.UPDATE_THEME_PROP]: (state, payload: Partial<EditableTheme>) => {
    const themeIndex = state.themes.findIndex((theme: EditableTheme) => theme.dark === state.theme.dark)

    for (const key in payload) {
      Vue.set(state.themes[themeIndex], key, (payload as any)[key])
      Vue.set(state.theme, key, (payload as any)[key])
    }
  },
  [MUTATIONS.UPDATE_THEME_APP_MODULE]: (state, payload: { key: string } & Partial<EditableThemeAppModule>) => {
    if (!state.theme.appModules[payload.key]) {
      Vue.set(state.theme.appModules, payload.key, {
        url: undefined
      })
    }

    for (const key in payload) {
      if (key === 'key') {
        continue
      }

      Vue.set(state.theme.appModules[payload.key], key, (payload as any)[key])
    }
  },

  [MUTATIONS.INCREMENT_LOADING]: (state) => {
    state._loading += 1
  },
  [MUTATIONS.DECREMENT_LOADING]: (state) => {
    state._loading -= 1
  },

  [MUTATIONS.SET_SWITCHING_THEME]: (state, payload?: boolean) => {
    state._switching = !!payload
  },
  [MUTATIONS.SET_HOVERED_APP_MODULE]: (state, payload?: string) => {
    state._hoveredAppModule = payload
  }
} as MutationTree<StateTheme>

export default mutations

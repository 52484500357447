// @ts-ignore
import StateTheme from './types'

const state = {
  domainId: null!,
  remoteThemes: [],
  themes: [],
  originalThemes: [],
  theme: null!,

  _loading: 0,
  _switching: false,
  _hoveredAppModule: undefined
} as StateTheme

export default state

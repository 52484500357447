import { RouteConfig } from 'vue-router'

export default [{
  path: '/theme/:id?/edit',
  name: 'theme.edit',
  component: () => import(/* webpackChunkName: "theme-views" */'../views/ThemeEdit.vue'),
  props: (route) => {
    return { id: typeof route.params.id === 'undefined' ? undefined : parseInt(route.params.id) }
  },
  meta: {
    breadcrumbs: {
      text: 'theme.themeBuilder'
    }
  }
}] as RouteConfig[]

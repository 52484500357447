import { ActionTree } from 'vuex'

import { apolloProvider } from '@simpl/core/plugins/apollo'
// @ts-ignore
import * as Queries from '../graphql'
// @ts-ignore
import { ACTIONS, MUTATIONS } from './consts'
import StateTheme from './types'
import { Theme } from '@simpl/core/types/graphql'

const actions = {
  [ACTIONS.GET_THEMES]: async ({ commit }, id) => {
    const result = await apolloProvider.defaultClient.mutate({
      mutation: Queries.THEME,
      variables: {
        domain_id: id
      }
    })

    const themes: Theme[] = (result.data?.themes || []).slice()

    commit(MUTATIONS.SET_THEMES, themes)
  },
  [ACTIONS.SAVE_THEME]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.STORE_THEME,
        variables: {
          input: payload
        }
      }).then((resp: any) => {
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  }
} as ActionTree<StateTheme, any>

export default actions

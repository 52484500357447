import { Module } from 'vuex'
import StateTheme from './types'

import state from './state'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state,
  mutations,
  actions
} as Module<StateTheme, any>
